<!--
 * @Description: 
 * @Author: Chp
 * @Date: 2021-04-02 15:53:23
 * @LastEditTime: 2021-06-01 14:52:10
 * @LastEditors: Chp
 * @Reference: 
-->
<template>
  <div class="mex-article">
    <ArticleTitle :info="detail" source="Mechanism"></ArticleTitle>
    <RichArticle :html="detail.contentDetails"></RichArticle>
  </div>
</template>

<script>
export default {
  data () {
    return {
      detail: '',//详情
      id: ''
    }
  },
  created () {
    this.id = this.$route.query.id;
    this.getDetail();
  },
  methods: {
    /**
     * @description: 获取详情
     * @param  {id:this.id}
     * @return {*}
     */
    getDetail () {
      let params = {
        id: this.id
      }
      this.$api.getDetail(params).then((res) => {
        this.detail = res.data.data.info;
        document.title = this.detail.contentTitle;
      })
    }
  },
}
</script>

<style scoped lang='scss'>
</style>